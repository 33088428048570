exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-antojitos-js": () => import("./../../../src/pages/antojitos.js" /* webpackChunkName: "component---src-pages-antojitos-js" */),
  "component---src-pages-book-a-table-js": () => import("./../../../src/pages/book-a-table.js" /* webpackChunkName: "component---src-pages-book-a-table-js" */),
  "component---src-pages-canceling-js": () => import("./../../../src/pages/canceling.js" /* webpackChunkName: "component---src-pages-canceling-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-drinks-js": () => import("./../../../src/pages/drinks.js" /* webpackChunkName: "component---src-pages-drinks-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-menu-food-js": () => import("./../../../src/pages/menu-food.js" /* webpackChunkName: "component---src-pages-menu-food-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-para-compartir-js": () => import("./../../../src/pages/para-compartir.js" /* webpackChunkName: "component---src-pages-para-compartir-js" */),
  "component---src-pages-postres-js": () => import("./../../../src/pages/postres.js" /* webpackChunkName: "component---src-pages-postres-js" */),
  "component---src-pages-soft-drinks-js": () => import("./../../../src/pages/soft-drinks.js" /* webpackChunkName: "component---src-pages-soft-drinks-js" */),
  "component---src-pages-tacos-js": () => import("./../../../src/pages/tacos.js" /* webpackChunkName: "component---src-pages-tacos-js" */),
  "component---src-pages-tostadas-js": () => import("./../../../src/pages/tostadas.js" /* webpackChunkName: "component---src-pages-tostadas-js" */)
}

