import "./src/styles/global.css"
import React from 'react';
import { Provider } from 'react-redux';
import store from './src/state/store';

export const wrapRootElement = ({ element }) => (
  <Provider store={store}>{element}</Provider>
);
/*import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="your-site-key">
            {element}
        </GoogleReCaptchaProvider>
    )
}*/