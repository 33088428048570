import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import formReducer from './formSlice';

const store = configureStore({
    reducer: {
        form: formReducer
    },
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;