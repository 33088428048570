import { createSlice } from '@reduxjs/toolkit';

const currentDate = new Date();
const defaultTabsFormValues = {
    guests: 2,
    time: "",
    date: currentDate // Convert to a serializable format
};

const formSlice = createSlice({
    name: 'form',
    initialState: {
        formData: defaultTabsFormValues,
        currentStep: 1
    },
    reducers: {
        setFormData(state, action) {
            state.formData = action.payload;
        },
        setCurrentStep(state, action) {
            state.currentStep = action.payload;
        }
    }
});

export const { setFormData, setCurrentStep } = formSlice.actions;
export default formSlice.reducer;